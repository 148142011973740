export default [
    {
        path: '/varianceanalysis',
        component: "__base",
        otherdata: { color: "caccounting", module: "Commentary Workflow" },
        children: [
            {
                // module: "TDS",
                path: "dashboard",
                component: "Dashboard.vue",
                meta: { title: "Dashboard" },
            },
            {
                // module: "TDS",
                path: "actualanalysis",
                component: "Analysis/ActualCycle.vue",
                meta: { title: "Actual Cycle" },
            },
            {
                // module: "TDS",
                path: "forecastanalysis",
                component: "Analysis/ForecastCycle.vue",
                meta: { title: "Forecast Cycle" },
            },
            {
                // module: "TDS",
                path: "approvals",
                component: "Approvals.vue",
                meta: { title: "Approvals Management" },
            },
            {
                // module: "TDS",
                path: "threshold",
                component: "Threshold.vue",
                meta: { title: "Materiality" },
            },
            {
                // module: "TDS",
                path: "accessrights",
                component: "AccessRights.vue",
                meta: { title: "Access Mangement" },
            },
            {
                // module: "TDS",
                path: "glselection",
                component: "GLforMngtCommentary.vue",
                meta: { title: "GL's Selection" },
            },
            {
                // module: "TDS",
                path: "glmanagement",
                component: "GLList.vue",
                meta: { title: "GL List" },
            },
            {
                // module: "TDS",
                path: "dataprocessing",
                component: "DataProcessing.vue",
                meta: { title: "Data Processing" },
            },
            {
                // module: "TDS",
                path: "userpermission",
                component: "UserPermission.vue",
                meta: { title: "User Permissions" },
            },
            {
                // module: "TDS",
                path: "myreportingunits",
                component: "MyReportingUnits.vue",
                meta: { title: "My Reporting Units" },
            },
            {
                // module: "TDS",
                path: "*",
                component: "",
            },
        ],
    },
]

